export default () => {
  return {
    checklist: {
      title: 'Ir al checklist',
      visible: true,
    },
    sistema: {
      title: 'Ir al sistema',
      visible: true,
    },
    irOrden: {
      title: 'Ir a la orden de trabajo',
      visible: false,
    },
    parteNuevo: {
      title: 'Crear un parte sobre este sistema',
      visible: false,
      icon: 'add'
    },
    map: {
      title: 'Ver en el mapa',
      visible: true,
      icon: 'map',
    },
    addMovimientoCaja: {
      title: 'Añadir movimiento de caja',
      visible: false,
      icon: 'movimientoCaja',
    },
    geolocalizar: {
      title: 'Capturar geolocalización actual',
      visible: true,
      icon: 'mapLocation',
    },
    modificar: {
      title: 'Modificar',
      visible: true,
      icon: 'edit',
    },
    iniciarCrono: {
      title: 'Iniciar cronómetro',
      visible: true,
      icon: 'cronometro',
    },
    detenerCrono: {
      title: 'Detener cronónometro',
      visible: true,
      icon: 'cronometro',
    },
    marcarTodoCorrecto: {
      title: 'Marcar todo correcto',
      visible: true,
      icon: 'checkAll'
    },
    finalizar: {
      title: 'Finalizar',
      visible: true,
      icon: 'finalizar',
    },
    reabrirParte: {
      title: 'Reabrir parte',
      visible: true,
      icon: 'undo',
    },
    resumenYFirma: {
      title: 'Resumen y firma',
      visible: true,
      icon: 'signature',
    },
    subirParte: {
      title: 'Subir parte',
      visible: true,
      icon: 'upload',
    },
    subirParteSinFinalizar: {
      title: 'Subir parte sin finalizar',
      visible: true,
      icon: 'upload',
    },
    volverADescargar: {
      title: 'Volver a descargar',
      visible: true,
      icon: 'download',
    },
  }
}

import { TABLA } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  async _addSubqueriesParteTrabajo (Vue, rows) {
    for (let row of rows) {
      row.emails = await Vue.$offline.sistemaTelefono.selectConSAT(row.sistema.idsistema)
      row.direccion = await Vue.$offline.clienteDireccion.formatearDireccion(row.cliente_direccion)
      row.cantAccionesRealizadas = (
        await Vue.$offline.parteTrabajo.selectCantAccionesRealizadas(row.parte_trabajo.idparte_trabajo)
      )[0].cant_acciones_realizadas
      row.cantAccionesTotal = (
        await Vue.$offline.parteTrabajo.selectCantAccionesTotal(row.parte_trabajo.idparte_trabajo)
      )[0].cant_acciones_total
      row.proximasRevisiones = await Vue.$offline.sistemaMant.selectProximasRevisiones(row.sistema.idsistema)
    }
    return rows
  },
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.cliente_direccion,
        tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
      )
      .innerJoin(
        tables.tsistema,
        tables.sistema.idtsistema.eq(tables.tsistema.idtsistema)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .innerJoin(
        tables.orden_trabajo_prioridad,
        tables.orden_trabajo.idorden_trabajo_prioridad.eq(tables.orden_trabajo_prioridad.idorden_trabajo_prioridad)
      )
      .leftOuterJoin(
        tables.almacen,
        tables.parte_trabajo.idalmacen.eq(tables.almacen.idalmacen)
      )
      .innerJoin(
        tables.forma_pago,
        tables.orden_trabajo.idforma_pago.eq(tables.forma_pago.idforma_pago)
      )
      .leftOuterJoin(
        tables.tincidencia_ot,
        tables.orden_trabajo.idtincidencia_ot.eq(tables.tincidencia_ot.idtincidencia_ot)
      )
      .leftOuterJoin(
        tables.tcierre_parte,
        tables.parte_trabajo.idtcierre_parte.eq(tables.tcierre_parte.idtcierre_parte)
      )
      .leftOuterJoin(
        tables.zona,
        tables.cliente_direccion.idzona_tecnico.eq(tables.zona.idzona)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .leftOuterJoin(
        tables.tmotivo_bloqueo_ot,
        tables.orden_trabajo.idtmotivo_bloqueo_ot.eq(tables.tmotivo_bloqueo_ot.idtmotivo_bloqueo_ot)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    return (await this._addSubqueriesParteTrabajo(Vue, rows))[0]
  },
  async selectMaterialAfectadoCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
        Vue.$offline.db.fn.sum(tables.material_sistema.unidades).as('count'),
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.parte_trabajo_matsist.estado.gt(0),
        )
      )
      .groupBy(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
      )
      .exec()
  },
  async selectMaterialRetiradoCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
        Vue.$offline.db.fn.sum(tables.material_sistema.unidades).as('count'),
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.parte_trabajo_matsist.estado.gt(0),
          tables.material_sistema.idalmacen.isNotNull(),
        )
      )
      .groupBy(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
      )
      .exec()
  },
  async selectSubsisCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.orden_trabajo_subsis.idorden_trabajo_subsis,
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
      )
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.subsis.estado.gt(0),
          tables.orden_trabajo_subsis.estado.gt(0),
        )
      )
      .exec()
  },
  async selectRevisionesCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.tactuacion.descripcion,
        tables.periodo_revision.descripcion,
        Vue.$offline.db.fn.count(tables.sistema_mant.idsistema_mant).as('count'),
      )
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema_mant,
        tables.orden_trabajo.idsistema.eq(tables.sistema_mant.idsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.sistema_mant.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .innerJoin(
        tables.periodo_revision,
        tables.sistema_mant.idperiodo_revision.eq(tables.periodo_revision.idperiodo_revision)
      )
      .groupBy(
        tables.tactuacion.descripcion,
        tables.periodo_revision.descripcion,
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.sistema_mant.estado.gt(0),
          tables.sistema_mant.activar.eq(true),
        )
      )
      .exec()
  },
  async selectSistemaCuotaCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const idConceptoCuotaDeMantenimiento = await Vue.$offline.conceptoCuotaTservicio.idConceptoCuotaDeMantenimiento()
    return await Vue.$offline.db
      .select(
        tables.sistema_cuota.idsistema_cuota,
        tables.concepto_cuota.descripcion_cuota,
      )
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema_cuota,
        tables.orden_trabajo.idsistema.eq(tables.sistema_cuota.idsistema)
      )
      .innerJoin(
        tables.concepto_cuota,
        tables.sistema_cuota.idconcepto_cuota.eq(tables.concepto_cuota.idconcepto_cuota)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.sistema_cuota.estado.gt(0),
          tables.sistema_cuota.idconcepto_cuota.in(idConceptoCuotaDeMantenimiento),
        )
      )
      .exec()
  },
  async selectMaterialInstalarCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
        Vue.$offline.db.fn.sum(tables.lparte_trabajo.unidades).as('count'),
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.subsis,
        tables.lparte_trabajo.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.lparte_trabajo.estado.gt(0),
        )
      )
      .groupBy(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
      )
      .exec()
  },
  async selectTiempoTrabajadoCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const tiempos = await Vue.$offline.db
      .select()
      .from(tables.tiempo_trabajado)
      .innerJoin(
        tables.empleado,
        tables.tiempo_trabajado.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
          tables.tiempo_trabajado.estado.gt(0),
        )
      )
      .exec()
    let tiempoManoObra = 0
    let tiempoDesplazamiento = 0
    for (const tiempo of tiempos) {
      if (tiempo.tiempo_trabajado.es_desplazamiento) {
        tiempoDesplazamiento += tiempo.tiempo_trabajado.ffin.getTime() - tiempo.tiempo_trabajado.finicio.getTime()
      } else {
        tiempoManoObra += tiempo.tiempo_trabajado.ffin.getTime() - tiempo.tiempo_trabajado.finicio.getTime()
      }
    }
    const countTiempos = (await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(tables.tiempo_trabajado.idtiempo_trabajado).as('count')
      )
      .from(tables.tiempo_trabajado)
      .where(
        Vue.$offline.db.op.and(
          tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
          tables.tiempo_trabajado.estado.gt(0),
        )
      )
      .exec())[0]
    return {
      count: countTiempos.count,
      tiempoManoObra,
      tiempoDesplazamiento,
    }
  },
  selectTecnicosAsignadosCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.empleado,
        tables.tecnico.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo),
        )
      )
      .exec()
  },
  async selectPersonasContactoCount (Vue, idsistema) {
    return await Vue.$offline.sistemaTelefono.selectContactosDelSistema(idsistema)
  },
  async selectFicherosCount (Vue, idparteTrabajo, idordenTrabajo, idsistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.fichero.idfichero).as('count'))
      .from(tables.fichero)
      .where(
        Vue.$offline.db.op.or(
          Vue.$offline.db.op.and(
            tables.fichero.idtabla.eq(TABLA.parte_trabajo.idtabla),
            tables.fichero.id.eq(idparteTrabajo),
          ),
          Vue.$offline.db.op.and(
            tables.fichero.idtabla.eq(TABLA.orden_trabajo.idtabla),
            tables.fichero.id.eq(idordenTrabajo),
          ),
          Vue.$offline.db.op.and(
            tables.fichero.idtabla.eq(TABLA.sistema.idtabla),
            tables.fichero.id.eq(idsistema),
          ),
        )
      )
      .exec())[0]
  },
  async selectSolicitudMaterialCount (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.solicitud_material.idsolicitud_material).as('count'))
      .from(tables.solicitud_material)
      .where(
        Vue.$offline.db.op.and(
          tables.solicitud_material.idparte_trabajo.eq(idparteTrabajo),
          tables.solicitud_material.estado.gt(0),
        )
      )
      .exec())[0]
  },
  async reabrirParte (Vue, idparteTrabajo) {
    await Vue.$offline.parteTrabajo.reabrirParte(idparteTrabajo)
  },
  async geolocalizar (Vue, idclienteDireccion, latitud, longitud) {
    await Vue.$offline.clienteDireccion.updateSync({
      idcliente_direccion: idclienteDireccion,
      latitud,
      longitud,
    })
  },
  async selectNserieFaltantes (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const nserieFaltantes = await Vue.$offline.db
      .select(
        tables.lparte_trabajo.idlparte_trabajo
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.articulo,
        tables.lparte_trabajo.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.lparte_trabajo.nserie.isNull(),
          tables.articulo.nserie_obligado.eq(true),
        )
      )
      .exec()
    return _.map(nserieFaltantes, 'lparte_trabajo.idlparte_trabajo')
  },
  async selectNotasCount(Vue, idparteTrabajo, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.solicitud_material.idsolicitud_material).as('count'))
      .from(tables.nota)
      .where(
        Vue.$offline.db.op.or(
          Vue.$offline.db.op.and(
            tables.nota.identificador.eq(idparteTrabajo),
            tables.nota.idtabla.eq(TABLA.parte_trabajo.idtabla)
          ),
          Vue.$offline.db.op.and(
            tables.nota.identificador.eq(idordenTrabajo),
            tables.nota.idtabla.eq(TABLA.orden_trabajo.idtabla)
          )
        )
      )
      .exec())[0]

  },
  async descargadoPorOtroTecnico(Vue, idparteTrabajo, idtecnico) {
    // Llama a la API porque comprueba antes que esté online en
    const resp = await Vue.$api.call('parteTrabajo.descargado_por_otro_tecnico', {
      idparte_trabajo: idparteTrabajo,
      idtecnico: idtecnico,
    })
    return resp.data.result
  },
}
